/* new=============================================================================== */
/* Ensure the navbar is fixed at the top and transparent */
/* Navbar styling with linear gradient background and transparency */

/* General Navbar Styles */
.homeLink:hover {
    color: #ED7D27 !important;
    text-decoration: none;
}

.header.scrolled {
    background-color: #E6E6E6;
    /* Background color on scroll */
}

.homeheaderDropdown {
    text-decoration: none;
    padding: 10px 15px !important;
    color: #212D40 !important;
    margin-right: 20px;
    background-color: transparent !important;
}

.active {
    color: #ED7D27 !important;
    text-decoration: none;
}

.homeLink:active {
    color: #ED7D27 !important;
    text-decoration: none;
}

.ImageModelHeader {
    color: #212D40 !important;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A !important;
    box-shadow: 1px 2px 40px 2px #212D4040;
}

.useModelHeader {
    padding: 13px 11px !important;
    color: #212D40 !important;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A !important;
    box-shadow: 1px 2px 40px 2px #212D4040;

    /* Optional, if you want to style the header as well */
    /* border-bottom: 1px solid #000000; */
}

.loginModelBg {
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);

}

/* .modal-content {
    background: linear-gradient(167deg, #1D2432 -3.64%, #060625 103.44%) !important;
    border: 1px solid #000000;
    box-shadow: 1px 2px 40px 2px #000000;

} */

.liteModal-content {
    color: #212D40 !important;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A !important;
    box-shadow: 1px 2px 40px 2px #212D4040;
}

/* .useModelHeader {
    background-color: #1D2432; 
    border-bottom: 1px solid #000000;
} */

/* .profile-modal {
    color: white;
} */

.userForm .form-control {

    /* background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%) !important;
    color: #fff !important;
    border: none; */
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%);
    border: 1px solid #cbc6c6;
    /* border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%); */
}

.userForm .form-control::placeholder {
    color: #212D40;
}

.userForm .form-select {
    /* background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%) !important;
    color: #fff !important;
    border: none; */
    font-size: 12px;
    /* background: linear-gradient(134.8deg, rgba(33, 45, 64, 0.1) 17.24%, rgba(33, 45, 64, 0.06) 95.25%); */
    border: 1px solid #cbc6c6;
    appearance: none;
}

.userForm select option {
    /* background: linear-gradient(134.8deg, rgba(33, 45, 64, 0.1) 17.24%, rgba(33, 45, 64, 0.06) 95.25%); */
    color: #212D40;
    appearance: none;
}

.containerBgColor {
    background-color: #060625;
}

.navbar-overlay {
    /* background: linear-gradient(45deg, black, #00000066); */
    background: #E6E6E6;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
    padding: 10px 0;
    /* opacity: 0.9; */
}

@media (max-width: 992px) {
    .navbar-overlay {
        padding: 10px;
    }

    .navbar-overlay .navbar-collapse {
        background: rgba(0, 0, 0, 0.9);
        padding: 15px;
    }

}

@media (min-width: 320px) and (max-width: 768px) {
    button.navbar-toggler.collapsed {
        display: block !important;
        background-color: antiquewhite;
    }

    .navbar-toggler {
        background-color: white;
    }

    div#responsive-navbar-nav {
        text-align: center;
        background: #d4d4d4;
    }
}

.custom-link {
    color: #212D40;
    text-decoration: none;
    padding: 10px 15px;
    margin: 0 5px;
    display: inline-block;
    transition: color 0.3s ease;
}

.custom-link.active {
    color: #ED7D27;
}

.navbar-overlay .nav-link {
    color: #FFFAFB;
    text-decoration: none;
    padding: 10px 15px;
    margin: 0 5px;
    display: inline-block;
    transition: color 0.3s ease;
}

.navbar-overlay .nav-link.active {
    color: #ED7D27;

}

.custom-button {
    background-color: #ED7D27;
    color: #FFFFFF;
    border: 1px solid #ED7D27;
    transition: background-color 0.3s ease;
}

/* .custom-button:hover,
.custom-button:focus {
    background-color: #900a4e;
    color: #FFFFFF;
} */

@media (max-width: 576px) {
    .navbar-overlay .navbar-collapse {
        text-align: center;
    }

    .custom-link {
        display: block;
        margin: 10px 0;
    }

    .custom-button {
        width: 100%;
        margin-top: 10px;
    }
}

.carousel-background {
    /* margin-top: 56px; */
    height: 400px;
    overflow: hidden;
}

.carousel-background img {
    object-fit: cover;
    height: 50%;
    width: 100%;
    filter: brightness(70%);
}

.background-image-container {
    background-size: cover;
    background-position: center;
    padding: 60px 0;
}

.carousel-caption {
    position: absolute;
    top: 16%;
    /* padding: 15px; */
    border-radius: 8px;
    /* width: 100%; */
    max-width: 100%;
}

.carousel-caption img {
    width: 100%;
    /* max-width: 300px;     */
    height: auto;
    object-fit: cover;
    /* margin-bottom: 15px; */
}

.caption-title {
    color: #FFFFFF;
    font-size: 42px;
    font-weight: 400;
}

.caption-subtitle {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 767px) {


    .caption-title {
        font-size: 28px;
    }

    .caption-subtitle {
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .carousel-caption {
        top: 12%;
    }

    .caption-title {
        font-size: 35px;
    }

    .caption-subtitle {
        font-size: 18px;
    }
}


.search-baree {
    /* background: linear-gradient(134.8deg, rgba(33, 45, 64, 0.1) 17.24%, rgba(33, 45, 64, 0.06) 95.25%); */
    border: 1px solid;
    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);


    /* border-radius: 25px; */
    /* padding: 5px; */

}

.custom-inputee {
    background-color: transparent;
    /* color: #fff; */
    border: none;
    /* padding: 9px; */
    font-size: 16px;
}

.custom-input::placeholder {
    color: #212D40;

}

.search-button {
    background-color: #ED7D27 !important;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;

}

.search-button img {
    width: 20px;
}

.search-button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    opacity: 0.6;

}

.search-bar {
    margin-top: -50px;
    padding: 15px;
    border-radius: 8px;
}

.search-bar-section {
    /* background: linear-gradient(90deg, #1D2432, #060625); */
    background-color: #E6E6E6;
    padding: 10px 0;
}

.search-baree-section {
    /* background: linear-gradient(90deg, #1D2432, #060625); */
    background-color: #E6E6E6;
    /* margin-top: 100px; */
    /* height: 107vh !important; */
}


/* .job-section {
    background: linear-gradient(90deg, #1D2432, #060625);

    margin-top: 140px;
    height: 107vh !important;
} */

.search-barDetail-section {
    /* background: linear-gradient(90deg, #1D2432, #060625); */
    background-color: #E6E6E6;
    /* margin-top: 100px; */
    height: 100% !important;
}

.search-bar .form-control,
.search-bar .form-select {
    max-width: 100%;
    background: linear-gradient(134.8deg, rgba(33, 45, 64, 0.1) 17.24%, rgba(33, 45, 64, 0.06) 95.25%);
    color: #212D40 !important;
    border: 1px solid;

    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);


    /* padding: 10px; */
}

select {
    width: 100%;
    /* background-color: #23263b; */
    color: #212D40 !important;
    border: none;
    padding: 5px;
    border-radius: 5px;
    appearance: none;
}

.loginNavLinkCOlor .nav-link {
    color: #212D40 !important;
}

/* .userForm .form-control,
.userForm .form-select {
    max-width: 100%;
    background-color: #23263b;
    color: white !important;
    border: none;
} */


.custom-placeholder::placeholder {
    color: #212D40;

}

.category-section {
    background-color: #E6E6E6;
    padding: 35px 0;
}

.placeholder-card {
    height: 165px;
    border-radius: 8px;
}




.icon-row .col {
    margin-bottom: 20px;
    /* Spacing between icons */
}

.category-icon {
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 6px;
    padding: 5px;
    background-color: #000;
    display: block;
    margin: 0 auto;

}


.icon-row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    /* gap: 1rem; */
    /* padding: 1rem; */
    /* overflow: hidden; */
}

/* .category-grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    
    gap: 16px;
   
    margin-top: 20px;
   
} */


.category-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    border: none;
    background-color: transparent;
    border: none;
    color: #fff;
}

/* .category-card {
    cursor: pointer;
  
    border: 1px solid #ddd;
   
    border-radius: 8px;
    
    overflow: hidden;
   
    transition: transform 0.2s;
   
} */

.icon-container {
    width: 125px;
    height: 125px;
    box-shadow: 2px 4px 10px 0px #00000040;
    backdrop-filter: blur(40px);
    border: 1px solid;
    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.25) 17.24%, rgba(255, 255, 255, 0.15) 95.25%);
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
}

.icon-container:hover {
    transform: scale(1.08);
    border: 2px solid #ED7D27;
    background: #ED7D27;
}

.icon-text {
    width: 118px;
    height: 118px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
}

.icon-container img {
    max-width: 60px;
    height: auto;
}

.category-cardMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    /* Adding pointer cursor for better UX */
}

/* .icon-containerMain {

    box-shadow: 2px 4px 10px 0px #00000040;
    backdrop-filter: blur(40px);
    border: 1px solid;
    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.25) 17.24%, rgba(255, 255, 255, 0.15) 95.25%);
    width: 120px;

    height: 100%;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  
} */
.icon-containerMain {
    position: relative;
    box-shadow: 2px 4px 10px 0px #00000040;
    backdrop-filter: blur(40px);
    border: 1px solid;
    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.25) 17.24%, rgba(255, 255, 255, 0.15) 95.25%);
    width: 120px;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Adjust as needed */
    height: 100%;
    /* Adjust as needed */
}

.icon-containerMain:hover {
    transform: scale(1.08);
    border: 2px solid #ED7D27;
    background: #ED7D27;
}

.icon-containerMain img {
    max-width: 60px;
    height: auto;
}


/* .card-containerMain {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
   
    gap: 20px;
    
    width: 100%;
} */

.card-body-responsive {
    padding: 10px;
}

.card-containerMain {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    /* Adjust column size */
    gap: 20px;
    width: 100%;
    /* overflow: hidden; */
    /* Prevent content overflow */
}

@media (max-width: 1440px) {
    .card-containerMain {
        grid-template-columns: repeat(6, 1fr);
        /* Reduce columns for smaller screens */
    }
}

@media (max-width: 1024px) {
    .card-containerMain {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .card-containerMain {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width: 1200px) {
    .icon-row {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (max-width: 992px) {
    .icon-row {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .icon-row {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 576px) {
    .icon-row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1200px) {
    .card-text-responsive {
        font-size: 0.9rem;
    }
}

@media (max-width: 992px) {
    .card-text-responsive {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .card-text-responsive {
        font-size: 0.7rem;
    }
}

@media (max-width: 576px) {
    .card-text-responsive {
        font-size: 0.6rem;
    }
}

.card-body-responsiveMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 6px 0 20px 0 !important;
    height: 100%;
    white-space: nowrap;
}

.card-body-responsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 6px 0 18px 0 !important;
    height: 100%;
    white-space: nowrap;
}

.card-text {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .card-text {
        font-size: 0.9rem;
    }
}

@media (max-width: 992px) {
    .card-text {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .card-text {
        font-size: 0.7rem;
    }
}

@media (max-width: 576px) {
    .card-text {
        font-size: 0.6rem;
    }
}

.background-image-container {
    position: relative;
    height: 400px;
    background-size: cover;
    background-position: center;
    filter: brightness(90%);



}


.caption-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
}

.caption-container img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 15px;
}




/* footer css */

.footer-logo img {
    max-width: 200px;
    max-height: 128px;
}

.social-icons {
    display: flex;
    gap: 20px;
}

.icon {
    background-color: #dad7d7;
    border-radius: 50%;
    /* display: flex; */
    align-items: center;
    width: 33px;
    height: 33px;
    text-align: center;
    padding: 6px;
}

.icon i {
    color: black;
    font-size: 22px;
}

.icon:hover {
    background-color: #ED7D27;
}


.newsletter .input-group {
    max-width: 300px;
}

.newsletter .form-control {
    border-radius: 0;
}

.newsletter .btn {
    /* border-radius: 0; */
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%) !important;
    /* border: none; */
    border-top: 1px solid linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%);
    border-right: 1px solid linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%);
    border-bottom: 1px solid linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%);
    border-left: none;
    width: 45px;
    padding-left: 8px;
    ;
}

.fa-map-marker-alt,
.fa-envelope,
.fa-phone-alt,
.fa-building {
    margin-right: 8px;
}

@media (max-width: 767px) {
    .footer-logo img {
        max-width: 120px;
    }
}


.custom-input {
    color: #fff;
    background-color: #060625;
    border: 1px solid rgb(73, 72, 72);
    border-radius: 6;
}

.custom-input::placeholder {
    color: #ccc;
}

.list-unstyled li {
    padding: 8px;

}



/* Category ptofile listing css */
.card-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    /* padding: 20px; */
    justify-items: center;
    /* background-color: #1D1E33; */
}

.card-custom {
    width: 242px;
    height: 272px;
    border-radius: 8px;
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background: #41423A26;
    border: 1px solid #9F5B5B33;
    backdrop-filter: blur(40px);
    box-shadow: 2px 4px 10px 0px #00000040; */
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.25) 17.24%, rgba(255, 255, 255, 0.15) 95.25%);
    border: 1px solid;

    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);

    backdrop-filter: blur(40px);

    box-shadow: 2px 4px 10px 0px #00000040;

}

.rating-card {
    width: 242px;
    height: 112px;
    background: #212D40;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0px 0px;
}

.job-rating-card {
    width: 242px;
    height: 112px;
    background-color: #212D40;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0px 0px;
}

.rating-text {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .rating-badge {
    background-color: #263238;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.card-title {
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: #212D40;
    font-weight: 500;
}

.card-subtitle {
    font-size: 12px;
    color: #212D40;
    font-weight: 500;
}

.card-text {
    font-size: 11px;
    color: #212D40;
    font-weight: 400;

}


.button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    gap: 10px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    gap: 10px;
}

.button-group .btn {
    font-size: 8px;
    font-weight: 500;
    /* height: 25px; */
}

.button-group .btn-danger {
    width: 108px;
    background-color: #FF007F;
    border: none;
}

.button-group .btn-success {
    width: 92px;
    background-color: #28A745;
    border: none;
}

@media (max-width: 1440px) {
    .card-grid {
        grid-template-columns: repeat(4, 1fr);
        /* Reduce columns for smaller screens */
    }
}

@media (max-width: 1200px) {
    .card-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 992px) {
    .card-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .card-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}




/* ============================================== */
/* Profile container styling */

.profile-container {
    background: #F7F7F7F7;
    border: 1px solid #F5F0F0;
    backdrop-filter: blur(40px);
    border-radius: 10px;
    box-shadow: 2px 4px 10px 0px #00000040;

    margin-top: 46px;
}

.profile-card {
    background-color: #23263b;
    border: none;
    color: white;
}

.card-header-section {
    background: #212D40;
    border-radius: 5px 5px 0px 0px !important;
}

.header-content {
    height: 200px;
}

/* Portfolio section */
.portfolio-title {
    color: #212D40;
    margin-top: 20px;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 500;
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* gap: 20px; */
    padding-left: 20px;
}

.portfolio-card {
    background: #41423A40;
    height: 122px;
    text-align: center;
    border-radius: 10%;
    width: 122px;
    border: 1px solid #41423A40
}

.portfolio-img {
    height: 120px;
    border-radius: 10%;
    /* Makes the image rounded */
    object-fit: cover;
    width: 120px;
    /* Ensures the image fits well inside the rounded area */
}

.card-title {
    color: #212D40;
    font-size: 1rem;
}

/* Contact card styling */
.contact-card {
    background: #41423A26;
    border: none;
    color: white;
}

.contact-info {
    margin-bottom: 15px;
    font-size: 0.9rem;
    color: #212D40 !important;

}

.rating-section .rating-star {
    font-size: 1.5rem;
    color: #4caf50;
    margin-right: 8px;
}

.rating-section .rating-value {
    /* color: #4caf50; */
    color: #FFFAFB;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    padding-top: 9px;

}

.rating-section .rating-count {
    color: #8f9bb2;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 300;
    padding-top: 9px;
}

.profile-title-name {
    color: #212D40;
    font-size: 24px;
    font-weight: 500;
}


.subtitle {
    color: #212D40;
    padding-right: 13px;
    padding-top: 20px;

}

.description {
    color: #212D40;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
}


.button-groupe {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

/* Contact info styling */

@media (max-width: 1200px) {
    .portfolio-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 992px) {
    .portfolio-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .portfolio-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .portfolio-grid {
        grid-template-columns: 1fr;
    }

    .header-content {
        height: 150px;
    }

    .rating-section .rating-star {
        font-size: 1.2rem;
    }

    .rating-section .rating-value {
        font-size: 1.2rem;
    }

    .rating-section .rating-count {
        font-size: 0.8rem;
    }
}








/* =========================================================================================================== */
.suggestions-list {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    padding: 0;
    margin-top: 2px;
}

.suggestion-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.suggestion-item:hover {
    background-color: #f2f2f2;
}

.suggestion-icon {
    margin-right: 10px;
    font-size: 18px;
}

.suggestion-details {
    display: flex;
    flex-direction: column;
}

.suggestion-name {
    font-weight: bold;
}

.suggestion-category {
    font-size: 12px;
    color: grey;
}

/* terms-and-conditions=========== ========================*/
/* Background and section container */
.terms-and-conditions-section {
    background: #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    text-align: center;


}

/* Main container for the terms content */
.terms-container {
    /* background-color: white; */
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border-radius: 10px;
    padding: 40px;
    border: 1px solid #212D401A;
    box-shadow: 1px 2px 40px 2px #212D4040;
    text-align: left;
    margin-top: 120px;
    margin-bottom: 10px;
    max-width: 85%;
}




/* Intro text at the top */
.intro-text {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 30px;
    color: #212D40;
}

.intro-text2 {
    font-size: 0.8rem;
    font-weight: 500;
    color: #212D40;
}

.spanText {
    font-size: 15px;
    font-weight: 600;
}

@media (max-width: 768px) {

    .terms-container {
        padding: 20px;
    }

    p {
        font-size: 1rem;
    }
}


/* select use type page css */

.user-type-box {
    width: 112px;
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #E6E6E6;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
    border: 3px solid #212D4040;
    box-shadow: 0px 4px 4px 0px #00000040;

}

.user-type-box:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px #ED7D27;
}

.user-icon {
    width: 76px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.selected-box {
    background-color: #ED7D27;
}


/* UserDashboard.css */

.dashboard-header {
    background-color: #FDD1D1;
    text-align: center;
    padding: 30px;
    font-size: 24px;
    font-weight: bold;
}


.dashboard-stats {

    padding: 9px;
    border-radius: 8px;
}

.stat-box-container {
    gap: 20px;

}


.stat-box {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #262F3D;
    border-radius: 10px;
    width: 150px;


}

.stat-value {
    font-size: 24px;
    color: white;
    font-weight: bold;
}

.stat-title {
    font-size: 14px;
    color: #A8B2D1;
}

.dashboard-container {
    padding: 0rem;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
}

.grid-item {
    /* background-color: #28283cba; */
    color: #212D40;
    padding: 1rem;
    border-radius: 0.5rem;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A;
    box-shadow: 1px 2px 40px 2px #212D4040;

}

.bookings {
    grid-column: span 8;
}

.enquiry {
    grid-column: span 4;
}

.table-dar th {
    padding: 0.5rem;
    background-color: #ded3cea3;
    font-size: 11px !important;
    /* Set font size to 11px */
    font-weight: 400 !important;
    text-align: left;
    color: #212D40 !important;
}

.table-dar td {
    padding: 2px 0px 0px 5px;
    font-size: 13px !important;
    text-align: left;
    font-weight: 400 !important;
    background: #c1c6cc7d;
    color: #212D40 !important;
}

.table-dar tbody tr:hover {
    background-color: #c7c4c1d4;
    color: #fff;
}

.table-actions {
    /* display: flex; */
    gap: 0.5rem;
}

.status-green {
    color: #28a745;
}

@media (max-width: 1200px) {
    .bookings {
        grid-column: span 12;
    }

    .enquiry {
        grid-column: span 12;
    }
}

@media (max-width: 768px) {
    .dashboard-container {
        padding: 0.5rem;
    }

    .grid-container {
        grid-template-columns: 1fr;
    }

    .grid-item {
        margin-bottom: 1rem;
    }

    .table-dark th,
    .table-dark td {
        padding: 0.5rem;
    }

    .table-actions {
        flex-direction: column;
    }

    .table-actions {
        display: flex;
        gap: 0.5rem;
    }
}


/* Adding space for the status label */
.status-green {
    background-color: #28a745;
    color: white;
    padding: 7px 12px;
    /* Added padding */
    border-radius: 5px;
}

.table-actions button {
    margin-left: 5px;
    padding: 2px 8px;
    border: none;
    border-radius: 5px;
    color: white;
}

.table-actions .btn-success {
    background-color: #28a745;
}

.table-actions .btn-warning {
    background-color: #FFC107;
}

.table-actions .btn-danger {
    background-color: #dc3545;
}




/* login hedder css  */


/* User Icon */
.user-icon {
    color: #ffffff;
    display: flex;
    align-items: center;
}

.user-icon svg {
    margin-right: 10px;
}

.user-icon span {
    font-size: 16px;
}

.user-icon .dropdown .dropdown-toggle:after {
    display: none !important;
}


/* booking   */
.bookings-section {
    background-color: #23263b;
    padding: 20px 0;
}

.booking-card-row {
    background-color: #2f364e;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.booking-details h5 {
    color: #ffffff;
}

.booking-details p {
    color: #a1a1a1;
}

.services .badge {
    background-color: #69b51d;
    color: white;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 20px;
}

.booking-actions p {
    color: #ffffff;
}

.update-status-btn {
    background-color: #ff4f81;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
}

.update-status-btn:hover {
    background-color: #e1436e;
}

.nav-links .nav-link.active {
    color: #ED7D27 !important;
}

.btn-close {
    background-color: white;
    border-radius: 10%;
    right: 20px !important;
    top: 15px !important;
}

.image-upload-container {
    position: relative;
    width: 100px;
    height: 100px;
}

.image-upload-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.camera-icon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.image-upload-container:hover .camera-icon-overlay {
    opacity: 1;
}

/* Custom class for responsive table */
.Associationscustom {
    border: 1px solid #212D401A;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    box-shadow: 1px 2px 40px 2px #212D4040;
}

.custom-responsive-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
}

.custom-responsive-table th,
.custom-responsive-table td {
    padding: 6px;
    text-align: left;
    border: 1px solid #1d1a1a;
    color: #212D40 !important;
}

.custom-responsive-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #212D40 !important;
}

.custom-responsive-table tbody tr {
    transition: background-color 0.3s;
}

/* .custom-responsive-table tbody tr:hover {
    background-color: #a6a0a0;
    color: white;
} */


.no-data {
    text-align: center;
    font-size: 18px;
    color: #999;
    margin-top: 20px;
}



.customC-select {
    /* appearance: none; */
    /* Hides the default dropdown arrow */
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%);


    /* Set background color */
    color: #212D40;

    /* Text color */
    padding-right: 40px;
    /* Space for the custom white arrow */
    position: relative;
    cursor: pointer;
    /* Pointer cursor for better UX */
    border: 1px solid;

    border-image-source: linear-gradient(208.71deg, rgba(33, 45, 64, 0.15) 1.84%, rgba(33, 45, 64, 0.15) 98.28%);



    /* border-image-source: linear-gradient(208.71deg, rgba(33, 45, 64, 0.05) 1.84%, rgba(33, 45, 64, 0.1) 98.28%); */


    border-radius: 4px;
}



/* Add a custom arrow */
.customC-select::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 6px 5px 0;
    border-style: solid;
    border-color: #495057 transparent transparent transparent;
    pointer-events: none;

}

/* Custom dropdown cursor */
.customC-select option {
    cursor: pointer;
    /* Ensures pointer cursor inside dropdown options */
}

/* Optional: Hover or focus effect for the select dropdown */
/* .customC-select:hover,
.customC-select:focus {
    border-color: #80bdff;
    outline: none;
} */

/* .form-select {
    --bs-form-select-bg-img: url('../../assets/images/frontPageImg/arrow.png') !important;
} */


/* .job-cards-container {
    padding: 10px;
    background-color: #10122b;
   
}

.jobbodyCard {
    padding: 10px 10px 10px 10px !important;
}

.job-card {
    background-color: #1d1f33;

    border: none;
    border-radius: 8px;
  
    width: 100%;
    height: auto;
    color: white;
    
    box-shadow: 2px 4px 10px 0px #00000040;


    backdrop-filter: blur(40px)
        
}

.company-logo-container {
    display: flex;
    justify-content: center;
}

.company-logo {
    width: 62px;
  
    border-radius: 4px;
    margin-top: 8px;
}

.job-title,
.job-location,
.job-description {
    overflow: hidden;
   
    white-space: nowrap;
   
    text-overflow: ellipsis;
  
}

.job-title {
    font-size: 14px;
    font-weight: 500;
    color: white;
}

.job-location {
    font-size: 10px;
    color: white;
   
}

.job-description {
    font-size: 10px;
    color: #dee2e6;
    
} */

.company-name {
    font-size: 12px;
    color: #6c757d;

}

/* .textjob-muted {
    color: #FFFAFB;
    font-size: 6px;
} */

/* @media (max-width: 992px) {
    .job-title {
        font-size: 14px;
    }

    .job-description {
        font-size: 13px;
    }
} */

.file-upload-containerDetailsPage {
    position: relative;
    width: 120px;
    height: 120px;
    background: #41423A40;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #41423A40
}

.file-inputDetailsPage {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* cursor: pointer; */
}

.file-upload-container {
    position: relative;
    width: 70px;
    height: 70px;
    background-color: #41423A40;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #41423A40
}

.file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.plus-icon {
    color: white;
    font-size: 24px;
    text-align: center;
    z-index: 1;
}

.error-message {
    color: red;
    margin-top: 5px;
    font-size: 14px;
}

.image-previews {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
}

.preview-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    display: inline-block;
}

.preview-image {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    position: relative;
}

/* Styles for the close (X) button */
.remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    line-height: 1;
}

.remove-icon:hover {
    background-color: darkred !important;
}

/* Main container to hold all sections */
.main-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;
    /* padding: 20px; */
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-all {
    color: #212D40;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

.view-all:hover {
    text-decoration: none;
    color: #ED7D27;
}

/* Jobs Section */
.jobs-section {
    /* background-color: #26392a; */
    border-radius: 10px;
    padding: 20px;
    height: 300px;
    /* Same height for uniformity */
    background: #50ADBFCC;


}

.job-card {
    display: flex;
    background-color: #ffffff36;
    /* padding: 15px; */
    margin-bottom: 15px;
    border-radius: 8px;
    color: #ffffff;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(40px);
    padding: 10px 10px 0px 10px;
    box-shadow: 2px 4px 10px 0px #00000040;

}

.institutes-card {
    display: flex;
    /* background-color: #b54c8480; */
    background-color: #ffffff36;
    ;
    /* padding: 15px; */
    margin-bottom: 15px;
    border-radius: 8px;
    color: #ffffff;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(40px);
    padding: 5px;
    box-shadow: 2px 4px 10px 0px #00000040;
}

.job-card .company-logo {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.job-details h4 {
    font-size: 13px;
    color: #ffffff;
}

.job-details p {
    font-size: 9px;
    color: white;
    margin-bottom: 3px;
}

.institutes-card .company-logo {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.institutes-details h4 {
    font-size: 13px;
    color: #ffffff;
}

.institutes-img {
    width: 100%;
    height: auto;
    max-height: 210px;
    /* object-fit: contain; */
    border-radius: 8px;
}


.institutes-details p {
    font-size: 9px;
    color: white;
    margin-bottom: 3px;
}


/* .news-section {
    border-radius: 10px;
    padding: 20px;
    height: 320px;
    background-color: #CE5F034D;

 
}

.news-item {
    background-color: #a87a544d;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.news-item h4 {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 5px;
}

.news-item p {
    font-size: 14px;
    color: #bbbbbb;
} */
/* Custom Swal popup styling */
.custom-swal-popup {
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #0000001A;
    box-shadow: 1px 2px 40px 2px #212D4040;
    color: #212D40;
}

/* Custom Swal title styling */
.custom-swal-title {
    color: #212D40;
}

/* Custom confirm and cancel button styling */
.custom-swal-confirm,
.custom-swal-cancel {
    color: #FFFFFF;
}

/* Additional button styling to match gradient theme */
.custom-swal-confirm {
    background-color: #72ADAE;
    border-color: #72ADAE;
}

.custom-swal-cancel {
    background-color: #ED7D27;
    border-color: #ED7D27;
}







.news-section {
    background-color: #ED7D27;
    /* Dark background color to match the screenshot */
    padding: 20px;
    border-radius: 10px;
    height: 300px;
}

.Institutesection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

/* Styling for section header */
.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.Newssection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
}

.Newssection-header h4 {
    font-size: 18px;
    color: white;
}

.section-header h4 {
    font-size: 18px;
    color: white;
}

/* Styling for each news item */
.news-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    background-color: #ffffff36;
    padding: 10px;
}

/* Image styling for news */
.news-image {
    width: 120px;
    /* Adjust the width based on desired size */
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 15px;
}

/* News content styling */
.news-content h4 {
    font-size: 14px;
    margin: 0 0 5px;
    color: white;
}

.news-content p {
    font-size: 10px;
    margin: 0;
    color: white;
    /* Light grey text for the description */
}

/* Institutes Section */
.institutes-section {
    border-radius: 10px;
    padding: 20px;
    height: 300px;
    /* Uniform height */
    /* background-color: #AA116380; */
    background-color: #3c507f80;

}

.editProfielCardHeader {
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    /* border: 1px solid #212D401A; */

}

.editProfielCard {
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A;
    box-shadow: 1px 2px 40px 2px #212D4040;

}


/* Responsive Design */
@media (max-width: 1200px) {
    .main-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .main-container {
        padding: 10px;
    }

    .jobs-section,
    .news-section,
    .institutes-section {
        height: auto;
    }
}




.logo-container {
    text-align: center;
    /* Center the content */
    margin-bottom: 10px;
    /* Space between logo and job details */
}

/* Styling for company logo */
.company-logo {
    display: block;
    margin: 0 auto;
    /* Center the logo */
}

/* Styling for company name under logo */
.company-name {
    display: block;
    margin-top: 5px;
    color: white;
    /* Light grey color */
    font-size: 8px;
    /* Smaller font for company name */
    text-align: left;
    /* Center the company name */
}


.job-list {
    max-height: 210px;
    /* Initially show 2-3 jobs */
    overflow: hidden;
    /* Hidden when the list is not expanded */
    transition: max-height 0.5s ease;
    /* Smooth transition for expanding */
}

.scroll-enabled {
    max-height: 222px;
    /* Set height large enough to show all jobs */
    overflow-y: auto;
    /* Enable scrolling */
}


.pdf-placeholder {
    width: 100px;
    /* Adjust size as needed */
    height: auto;
}

.pdf-download-link {
    color: #007bff;
    display: block;
    margin-top: 5px;
    text-align: center;
}

.appledJobDetailsFooter {
    background: linear-gradient(167deg, #1D2432 -3.64%, #060625 103.44%) !important;
}


.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #d2cbd37e;
    border: 1px solid;
}

.nav-pills .nav-link {
    color: #212D40;
    background-color: transparent;
}


.userForm .form-check-input {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 20px;
    height: 20px;
    border-radius: 4px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.userForm .form-check-input:checked {
    background-color: #AA1163;
    border-color: #AA1163;
}

.userForm .form-check-label {
    color: #fff;
    font-size: 1rem;
    margin-left: 8px;
    cursor: pointer;
}

.userForm .form-check-input:focus {
    box-shadow: 0 0 5px #AA1163;
    outline: none;
}

.privacy-policy-link {
    color: #AA1163;
    text-decoration: underline;
    cursor: pointer;
}

.privacy-policy-link:hover {
    color: #ED7D27 !important;
    /* Darker shade on hover */
    text-decoration: underline;
}

.modalbodyTermAndCondition {
    max-height: 500px;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

/* .news-section .job-list {
    max-height: 220px;

    overflow-y: auto;
    scrollbar-width: thin;

    scrollbar-color: #a87a544d;

} */

/* WebKit Browsers (e.g., Chrome, Edge, Safari) */
/* .news-section .job-list::-webkit-scrollbar {
    width: 8px;
   
} */

/* .news-section .job-list::-webkit-scrollbar-thumb {
    background-color: #a87a544d;
 
    border-radius: 10px;
   
} */

/* .news-section .job-list::-webkit-scrollbar-track {
    background-color: #1D1D1D;

} */
/* ================================================= */
.news-section .job-list::-webkit-scrollbar-thumb {
    background-color: #f2ebeb;
    /* Cursor (thumb) color */
    border-radius: 4px;
    /* Rounded corners for the thumb */
}

/* .news-section .job-list::-webkit-scrollbar-thumb:hover {
    background-color: #493b36;
} */

.jobs-section .job-list::-webkit-scrollbar-thumb {
    background-color: #e7dfdf;

    border-radius: 4px;

}



/* body::-webkit-scrollbar-track {
    background: #f1f1f1;
   
} */


.contactUs-section {
    margin-bottom: 40px;
}

.addresscustom-link {
    color: #72ADAE;
    /* Default link color */
    text-decoration: underline;
    /* Remove underline */
    transition: color 0.3s ease;
    /* Smooth color transition */
}

.addresscustom-link:hover {
    color: #ED7D27;
    /* Hover color */
    text-decoration: underline;
    /* Optional underline on hover */
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 200px;
    /* Adjust max-width as needed */
}


/* new theme color and css
========================================================= */
.createbtnColor {
    background: #72ADAE !important;
    color: #FFFFFF !important;
    border: 0.2px solid #72ADAE !important;

}

.btnColor {
    background: #ED7D27 !important;
    border: 0.2px solid #ED7D27 !important;
    color: #FFFFFF !important;
}

.subFooterContainer {
    background: #E6E6E6;
    border-top: 1px solid rgb(82 74 74 / 50%);
}

.allTextColor {
    color: #212D40 !important;
}

.otpcustom-box {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    border-radius: 4px;
    margin-right: 10px;
    /* Conditional margin needs to be handled in JS */
    background: linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%);
    border: 1px solid #cbc6c6;
    /* border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%); */
}

.varifedImp {
    background: #212D4040;
    border: 1px solid;
    border-image-source: linear-gradient(208.71deg, rgba(0, 0, 0, 0.05) 1.84%, rgba(0, 0, 0, 0.1) 98.28%);
}

.profileUserRegistContainer {
    padding: 20px;
    border-radius: 10px;
    position: relative;
    z-index: 4;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A;
    box-shadow: 1px 2px 40px 2px #212D4040;


}

.addEditJobContainer {
    padding: 20px;
    border-radius: 10px;
    position: relative;
    z-index: 4;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    border: 1px solid #212D401A;
    box-shadow: 1px 2px 40px 2px #212D4040;
    width: 50%;
}

.contectUseForm {
    border: 1px solid #212D401A;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    box-shadow: 1px 2px 40px 2px #212D4040;

}

.contectUsAddresCard {
    border: 1px solid #212D401A;
    background: linear-gradient(167deg, #E6E6E6 -3.64%, #E6E6E6 103.44%);
    box-shadow: 1px 2px 40px 2px #212D4040;
    padding: 20px;
    border-radius: 10px;
    height: 355px
}

.card-image {
    display: block;
    transition: opacity 0.3s ease;
}

.card-image-hover {
    display: block;
    position: absolute;
    width: 120px;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.category-cardMain:hover .card-image-hover {
    opacity: 1;
}

.category-cardMain:hover .card-image {
    opacity: 0;
}

.category-card:hover .card-image-hover {
    opacity: 1;
}

.category-card:hover .card-image {
    opacity: 0;
}